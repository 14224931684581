.angebot-box {
    width: calc(50% - 32px * 1 / 2);
}

.angebot-box:first-child {
    margin-right: 32px;
}

.angebot-box__marke {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    padding: 10px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.angebot-box__marke img {
    width: 86px;
    height: auto;
}

.angebot-box--img {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 32px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.angebot-box__content {
    color: #fff;
    background: #f7b800;
    padding: 6px 10px;
    font-family: var(--font-cdbold);
}

.angebot-box__content h3 {
    font-family: var(--font-cdbold);
    margin: 4px 0;
}

.angebot-boxen {
    display: flex;
    flex-wrap: wrap;
}

.kacheln.kacheln--angebot {
    padding: 3rem 0;
    margin: 0;
}

.kacheln--angebot h2.boxen--titel  {
    margin-top: 0;
}

@media screen and (max-width: 767px) {
    .angebot-box:first-child {
        margin: 0
    }
    .angebot-box:nth-child(n) {
        width: 100%;
        margin: 0;
        margin-bottom: 16px;
    }

}
