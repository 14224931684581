
.sub-footer {
  text-align: center;
}

.footer-menu ul {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  margin-top: 2rem;
}

.footer-menu ul li {
  padding: 0.5rem;
}

.footer-menu ul li a {
  color: var(--font-color);
  font-size: 1rem;
  font-family: var(--font-normal);
}

.info-footer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  flex-wrap: wrap;
  margin-top: 0;
}
.footer-banner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  height: auto;
}
.footer-banner img{
  display: block;
  max-width: 100%;
}
.footer-banner.left {
  background: #ffbc00;
}
.footer-banner.left .footer-banner-img{
  margin-left: auto;
}
.footer-banner.right {
  background: #4d4d4d;
}
.footer-banner.right .footer-banner-img{
  margin-right: auto;
}

.footer__hersteller > .container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
}

.footer__hersteller-image {
    height: 90px;
    width: 251px;
    text-align: center;
}

.footer__hersteller-image:first-child {
    height: 60px

}

.footer__hersteller-image img {
  max-width: 100%;
  max-height: 100%;
}
@media screen and (min-width: 48rem) {
  .footer-banner {
    width: 50%;
    height: 246px;
  }
}