.header-image {
  background-size: cover;
  background-position: center;
}

.header-image h1 {
  color: white;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-family: var(--font-bold);
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 2rem;
  line-height: 2rem;
}

.header-text {
  background: var(--color-primary);
}
.header-text h1 {
  color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-family: var(--font-bold);
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 2rem;
  margin-bottom: 0;
}
@media screen and (min-width: 48rem) {
  .header-image h1 {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
  .header-text h1 {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
}