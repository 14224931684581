.layout-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: #f2f2f2;
}

.layout-4 h2 {
  margin-top: 0;
  text-align: center;
}

.layout-4 h2::after {
  content: "";
  width: 10rem;
  height: 2px;
  display: block;
  position: relative;
  margin: auto;
  margin-top: 1rem;
  background: var(--color-primary);
}

.gallary-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

.gallary-img {
  width: 100%;
  padding: 1rem;
  margin-bottom: 2rem;
}

.gallary-bild {
  width: 100%;
  display: block;
  border: solid 10px white;
  border-radius: 10px;
  box-shadow: 0px 14px 15px 1px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 14px 15px 1px rgba(0, 0, 0, 0.28);
  -webkit-box-shadow: 0px 14px 15px 1px rgba(0, 0, 0, 0.28);
}

.gallary-bild img {
  width: 100%;
  display: block;
  max-width: 100%;
  height: auto;
}


.left .gallary-bild {
  transform: rotate(-5deg);
}
.left .gallary-text {
  text-align: left;
}
.right .gallary-bild {
  transform: rotate(7deg);
}
.right .gallary-text {
  text-align: right;
}
.layout-4-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
@media screen and (min-width: 48rem) {
  .layout-4-wrapper .gallary-box {
    width: 50%;
  }
}
@media screen and (min-width: 64rem) {
  .layout-4-wrapper .gallary-box {
    width: 32%;
  }
}