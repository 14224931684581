.kacheln {
  margin-top: var(--gutter);
}

.boxen {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-start;
  align-items: stretch;
  padding-bottom: 2rem;
}

.box {
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
}

.boxen--img {

}

.boxen--link {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background: #f2f2f2;
  padding: 0.5rem;
  /*transition: background 0.5s ease-in;*/
}

.boxen--link:hover {
  background: var(--color-primary);
}

.boxen--link h3 {
  font-family: var(--font-cdbold);
  font-size: 1.4rem;
  text-transform: uppercase;
  padding-bottom: 1rem;
  padding-top: 1rem;
  width: 100%;
  color: var(--font-color);
  display: block;
  position: relative;
  margin: 0;
  /*transition: color 0.5s ease-in;*/
}

.boxen--link:hover h3 {
  color: white;
}

.boxen--triangle {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: rotate(360deg) translate(-50%, 0);
  width: 0px;
  height: 0px;
  -webkit-transform:rotate(360deg) translate(-50%, 0) ;
  border-style: solid;
  border-width: 0 15px 15px 15px;
  border-color: transparent transparent #ffffff transparent;
}

.boxen--titel {
  text-align: center;
}
.boxen--titel::after {
  content: "";
  width: 10rem;
  height: 2px;
  display: block;
  position: relative;
  margin: auto;
  margin-top: 1rem;
  background: var(--color-primary);
}
@media screen and (min-width: 35.5rem) {
  .box-leistung--content {
    /*top: 60%;*/
  }

  .box {
    width: calc(98% / 2);

  }
}

@media screen and (min-width: 64rem) {
  .box {
    width: calc(97% / 3);
    margin-bottom: 0;
  }
}

