header {
  background-size: auto;
  background: white;
  position: relative;
  color: var(--font-color);
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .55);
  -moz-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .55);
  -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .55);
}

.header-logo-mobil {
  margin-left: 1rem;
  padding: 0.2rem;
}

.header-logo-mobil a {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.header-logo-mobil img {
  height: 3rem;
}

.hamburger-button svg {
  width: 2rem;
  height: 2rem;
  display: block;
  margin: auto auto;
}

.hamburger-button {
  height: 2.5rem;
  width: 2.5rem;
  outline: none;
  border: medium none;
  color: #f5f5f5;
  font-weight: 700;
  padding: 0;
  cursor: pointer;
  margin-left: auto;
  margin-bottom: auto;
  margin-top: auto;
  margin-right: 1rem;
}

.hamburger-button {
  background-color: var(--color-primary);
}

.hamburger-button:hover {
  background-color: var(--color-primary);
}

.header-right {
  display: none;
  width: 100%;
  text-align: right;
  padding: 1rem;
}

.info-group {
  color: white;
  font-size: 1.1rem;
}

.info-group img {
  height: 1.1rem;
  width: 1.1rem;
}

.header-logo {
  display: none;
}

.header-logo a {
  margin-left: 1rem;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.header-logo img {
  max-height: 4rem;
  height: 4rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.main-nav {
  border-top: solid 1px #eeeeee;
  background: #f7f9fb;
}

ul.main-navigation {
  list-style: none;
  padding-left: 0;
  margin-top: var(--gutter);
}

ul.main-navigation > li {
  line-height: 0.5rem;
  height: auto;
}

a.info-group img {
  margin-right: 0.5rem;
}

a.info-group:last-of-type {
  margin-right: 0.5rem;
}

a.info-group {
  margin-right: 2.5rem;
  display: flex;
  justify-content: flex-end;
  width: auto;
  align-content: center;
  align-items: center;
}

a:hover.info-group {
  color: var(--color-primary);
}

.headerInfo {
  display: none;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
.headerInfo .container{
  width: 100%;
}

.headerContact--img::after {
    content: '';
    position: absolute;
    right: -7px;
    width: 0;
    height: 0;
    -webkit-transform:rotate(360deg);
    border-style: solid;
    border-width: 4px 0 4px 6px;
    border-color: transparent transparent transparent #eeeeee;
}

@media screen and (min-width: 64px) {
  .headerInfo .container {
    width: 60rem;
    margin: 0 auto;
  }
}

@media screen and (min-width: 80px) {
  .headerInfo .container {
    width: 75rem;
  }
}
ul.main-navigation {
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}

@media screen\0 {
  ul.main-navigation {
    display: block
  }
}
.main-navigation li.header-logo {
  align-self: flex-start;
  margin-right: auto;
}

.main-navigation li.header-logo a {
  margin-left: 0;
  padding-left: 0;
}

.main-navigation li > a {
  display: block;
  position: relative;
  /*padding: 1rem;*/
  font-size: 1.4rem;
  color: var(--font-color);
  font-family: var(--font-cdmedium);
  font-weight: 500;
  border-bottom: solid 2px transparent;
}

.main-navigation li > a {
  padding: 5px;
}

.main-navigation li > a > span {
  padding: 11px;
}

.main-navigation > li > a:hover > span,
.main-navigation > li > a:focus > span,
.main-navigation > li > a:active > span,
.main-navigation > li.active > a > span {
  /*color: var(--color-primary);*/
  border-bottom:solid 2px var(--color-primary);
}

.main-navigation > li.header-logo > a:hover > span,
.main-navigation > li.header-logo > a:focus > span,
.main-navigation > li.header-logo > a:active > span,
.main-navigation > li.header-logo.active > a > span {
  border-bottom: solid 2px transparent;
}


.main-navigation > li > a:hover,
.main-navigation > li > a:focus,
.main-navigation > li > a:active,
.main-navigation > li.active > a {
  color: var(--color-primary);
  /*border-bottom:solid 2px var(--color-primary);*/
}
.main-navigation > li.header-logo > a:hover,
.main-navigation > li.header-logo > a:focus,
.main-navigation > li.header-logo > a:active,
.main-navigation > li.header-logo.active > a {
  /*border-bottom: solid 2px transparent;*/
}

.menu-img {
  background: #383437;
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-right: 0.5rem;
  margin-left: 0;
  /*transition: background 0.5s, border 0.5s;*/
}


.menu-icon {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.menu-icon img {
  height: 0.8rem;
  width: 0.8rem;
}

.dropdown {
  left: 0;
  top: 55px;
  background-size: auto;
  background: white;
  /*box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);*/
  /*-moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);*/
  /*-webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);*/
}

a.dropdown-link.dropdown-field {
  line-height: 1rem;
  display: block;
  text-align: initial;
}

.dropdown-list-item {
  padding: 0 var(--gutter);
}

.dropdown > li:last-of-type {
  border-right: none;
}

.dropdown > li:first-of-type {
  padding-left: 0;
}

.dropdown-list-item ul {
  font-size: var(--font-size-small);
  list-style-type: disc;
}

.dropdown-link {
  display: block;
  color: var(--font-color);
  text-decoration: none;
  padding: 0.5rem;
}

.submenu-2 {
  margin-bottom: var(--gutter-horizontal);
}

.dropdown-link-layer-2 {
  color: var(--font-color);
  text-decoration: none;
}

.dropdown-link:hover,
.dropdown-link-layer-2:hover {
  color: var(--color-primary);
}

.main-navigation .open {
  /*background-color: var(--color-secondary);*/
  /*color: white;*/
}

.main-navigation .open + .dropdown {
  display: flex;
  flex-wrap: wrap;
  z-index: 10;
}

.logo--container {
  display: none;
}

.header-img-left {
  display: none;
}

.headerInfo-inner {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 1.5rem 0;
}

.headerInfo-contact {
  flex-wrap: wrap;
  display: none;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
}

.headerContact--box {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  color: var(--font-color);
  margin-left: 1rem;
}

.headerContact--img {
  height: 2rem;
  width: auto;
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.headerContact--img img {
  height: 1.3rem;
  width: auto;
  margin: auto;
  display: block;
}

.social-icon {
  border-right: solid 1px #eeeeee;
}

.social-icon img {
  height: 1.3rem;
  width: 1.3rem;
}

.social-icon.facebook img {
  height: 1.0rem;
  width: 1.0rem;
}

.headerContact--content .text-bold {
  font-size: 1.2rem;
}

.telefon .headerContact--content {
  line-height: 1.2rem;
}

.telefon .headerContact--img {
  padding-right: 0.5rem;
  border-right: solid 1px #eeeeee;
}

.header-button {
  border: solid 1px var(--color-primary);
  padding: 0.5rem 1rem;
  /*transition: background-color 0.5s linear, color 0.5s linear;*/
  position: relative;
}

.header-button:hover {
  background-color: var(--color-primary);
}

.header-button .header-button-text {
  font-family: var(--font-bold);
  font-weight: 700;
}

.header-button:hover .header-button-text {
  color: white;
}

.box--eck-top {
  content: '';
  background: transparent;
  position: absolute;
  top: -2px;
  left: -2px;
  width: 1rem;
  height: 1rem;
  border-left: solid 3px var(--color-primary);
  border-top: solid 3px var(--color-primary);
}

.box--eck-bottom {
  content: '';
  background: transparent;
  position: absolute;
  bottom: -2px;
  right: -2px;
  width: 1rem;
  height: 1rem;
  border-right: solid 3px var(--color-primary);
  border-bottom: solid 3px var(--color-primary);
}

@media screen and (min-width: 48rem) {
  .header-logo {
    display: block;
  }

  .headerInfo {
    display: flex;
  }

  .header-right {
    display: block;
  }

  .main-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }

  ul.main-navigation {

    width: 100%;
    margin-bottom: 0;
    margin-top: 0;
  }

  ul.main-navigation--left {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: flex-end;
  }

  ul.main-navigation--right {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;

  }

  .header-img-left {
    display: block;
  }

  .header-img-left img {
    width: auto;
    height: 100%;
  }

  ul.main-navigation li {
    display: inline-block;
  }

  ul.main-navigation > li {
    display: inline-block;
    position: relative;
  }

  ul.main-navigation > li:not(.header-logo) > a {
    display: flex;
    height: 100%;
    text-align: left;
    line-height: 1rem;
  }

  /* 2. Ebene */
  .dropdown {
    display: none;
    position: absolute;
    background-color: white;
    margin-bottom: 0;
    width: 19rem;
    padding: 0;
    list-style-type: none;
    z-index: 100;
  }

  ul.main-navigation li .dropdown-link,
  ul.main-navigation li .dropdown-link-layer-2 {
    color: var(--font-color);
  }

  ul.dropdown.first li.active .dropdown-link,
  ul.dropdown.first li.active .dropdown-link-layer-2 {
    color: white;
  }

  ul.main-navigation a:hover .dropdown-link,
  ul.main-navigation a:hover .dropdown-link-layer-2 {
    color: var(--color-primary);
  }

  ul.dropdown li {
    display: block;
    width: 100%;
    padding: 0;
    margin-left: 0;
    font-size: var(--font-size);
  }

  ul.dropdown li a:hover {
    color: white;
  }

  .main-navigation .open {
    background: #fff;
    border-bottom: 2px solid var(--color-primary);
  }


  .headerInfo-contact {
    display: flex;
  }
}

@media screen and (min-width: 55rem) {

  .header-img-left {
    display: none !important;
  }

  ul.main-navigation--right {
    justify-content: flex-end;
    align-items: flex-end;
  }

  ul.main-navigation--left {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .logo--container {
    display: block;
    width: 20%;
    height: 0;
  }

  .head--logo {
    width: 16%;
    margin: 0 auto;
  }

  img.img--logo {
    width: 110px;
    left: 50%;
    margin-left: -55px;
    top: -18px;
    z-index: 601;
  }

  .head--kreis {
    width: 170px;
    min-width: 170px;
    height: 160%;
    position: absolute;
    display: inline-block;
    background-color: #fff;
    border-radius: 0 0 100px 100px;
    -moz-border-radius: 0 0 100px 100px;
    -webkit-border-radius: 0 0 200% 200%;
    left: 50%;
    margin-left: -85px;
    top: -60px;
    z-index: 80;
    box-shadow: 0 1px 14px 0 rgba(0, 0, 0, .55);
    -moz-box-shadow: 0 1px 14px 0 rgba(0, 0, 0, .55);
    -webkit-box-shadow: 0 1px 14px 0 rgba(0, 0, 0, .55);
  }

  .head--white {
    width: 210px;
    min-width: 210px;
    height: 101%;
    position: absolute;
    display: inline-block;
    background-color: #fff;
    -moz-border-radius: 0 0 100px 100px;
    /* -webkit-border-radius: 0 0 200% 200%; */
    left: 0;
    margin-left: -90px;
    top: -60px;
    z-index: 80;
    /* box-shadow: 0 1px 14px 0 rgba(0, 0, 0, .55); */
    -moz-box-shadow: 0 1px 14px 0 rgba(0, 0, 0, .55);
    /* -webkit-box-shadow: 0 1px 14px 0 rgba(0, 0, 0, .55); */
  }
}



@media screen and (min-width: 64rem) {
}

@media screen and (min-width: 80rem) {
  img.img--logo {
    width: 110px;
    margin-left: -55px;
    top: -18px;
  }
}


.menu2-img img {
  width: 100%;
  height: auto;
}

.submenu.first-layer.menu-icon[title="Unsere Marken"]:hover {
  background: #fff;
}

.submenu.first-layer.menu-icon[title="Unsere Marken"] ~ .dropdown {
  width: 122px;
  top: 47px;
  padding-top: 20px;
}


ul.dropdown.first {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.4);
}

.main-navigation .open {
  box-shadow: 0 -2px 4px 0 rgba(0,0,0,.4);
  padding:5px;
  border-bottom: none;
  z-index: 10;
}
.main-navigation .open span {
  border-bottom: 2px solid var(--color-primary);
  padding: 11px;
}
body[id="13"] .header-image, /* detail */
body[id="37"] .header-image /*listing*/ {
    height: 260px;
    display: flex;
    align-items: center;
}

/* ie */
.headerInfo {
    justify-content: flex-start;
}

@media screen and (min-width: 48rem) {
    .main-nav {
        justify-content: flex-start;
    }
}