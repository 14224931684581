/*
 * Filename: base.css
 */

* {
  position: relative;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  background: white;
}

.pure-img {
  width: 100%;
}

table {
  width: 100%;
  margin-bottom: var(--gutter);
}

th,
td {
  padding: .2rem;

  border-bottom: 1px solid var(--color-grey-light);
}

code {
  display: inline-block;

  padding: 0 .2em;

  color: var(--code);
  background: #eee;
}

a {
  color: var(--color-primary);
  text-decoration: none;
  /*transition: color 0.5s, background 0.5s;*/
}

/*a:not(.btn):hover {*/
  /*color: color(var(--color-secondary));*/
/*}*/


/** Firefox Glow bei invaliden Formularfeldern entfernen */
:invalid {
  box-shadow: none;
}

:-moz-submit-invalid {
  box-shadow: none;
}

:-moz-ui-invalid {
  box-shadow: none;
}

select {
  /*-webkit-appearance: none;*/
}
