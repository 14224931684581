@media screen and (max-width: 1279px) {
    .kdz-flex-col:nth-child(1) {
        width: 20%;
    }
    .kdz-flex-col:nth-child(2) {
        width: 20%;
    }
    .kdz-flex-col:nth-child(3) {
        width: 60%;
    }
    .kdz-flex-col:nth-child(4) {
        width: 100%;
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .filter-main--schlaf {width: 200px;}
    .filter-main--sitz {width: 198px;}
    .filter-main--erstzulassung {width: 240px;}
    .filter-main--neuwagen {
        width: 120px;
    }
    .filter-main--gebrauchtwagen { width: 146px; }

    .filter-main--schlaf,
    .filter-main--sitz ,
    .filter-main--erstzulassung ,
    .filter-main--neuwagen,
    .filter-main--gebrauchtwagen { margin-bottom: 8px; }

    .car-box__header {
        position: unset;
    }

    .listing--car-box {
        position: relative;
    }
    .car-box__image {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 100px;
        width: 150px;
        z-index: 20;
        background-position: right center;
    }
}



@media screen and (max-width: 969px) {
    .kdz-flex-col:nth-child(1) {
        width: 100%;
    }
    .filter-main--modell {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .filter-main--modell > * {
        margin-bottom: 5px;
    }
    .filter-main--modell > *:not(:last-child) {
        margin-right: 5px;
    }
    .kdz-flex-col:nth-child(2) {
        width: 20%;
    }
    .kdz-flex-col:nth-child(3) {
        width: 80%;
    }
}



@media screen and (max-width: 829px) {
    .listing--car-box {
        width: 100%;
    }
    .listing--car-box:nth-child(n) {
        margin: 0;
        margin-bottom: 16px;
    }
    .car-box__image {
        position: unset;
        background-position: center center;
    }
}



@media screen and (max-width: 767px) {
    .kdz-flex-col:nth-child(2) {
        width: 100%;
    }
    .filter-main--bauart {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .filter-main--bauart > * {
        margin-bottom: 5px;
    }
    .filter-main--bauart > *:not(:last-child) {
        margin-right: 5px;
    }

    .kdz-flex-col:nth-child(3) {
        width: 100%;
    }
    .filter-main--laenge {
        margin-bottom: 40px;
    }
    .kdz-flex-col:nth-child(4) {
        justify-content: center;
    }


    .filter-laenge-right,
    .filter-laenge-left {
        display: none;
    }

    #min-preis-value,
    #max-preis-value,
    #min-laenge-value,
    #max-laenge-value {
        position: static;

    }

    #min-laenge-value::after {
        content: ' cm';

    }

    #max-laenge-value::after {
        content: ' cm';
    }

    #min-preis-value,
    #min-laenge-value {
        margin-right: 10px;
    }

    #max-preis-value,
    #max-laenge-value {
        margin: 10px;
    }
}

@media screen and (max-width: 478px) {
    .car-box__image {
        position: absolute;
        background-position: right center;
        height: 80px;
        width: 100px;
    }
    #max-laenge-value, #max-preis-value {
        right: 2%;
    }
    #min-laenge-value, #min-preis-value {
        left: 2%
    }
    .listing--filter-main {
        padding: 1.5rem .5rem;
    }
    .filter-laenge-left, .filter-laenge-right {
        width: 63px;
    }
    .topviewimage {
        height: 200px;
    }
}





/*@media screen and (max-width: 1279px) {*/
    /*.kdz-flex-col:nth-child(1) {*/
        /*width: 20%;*/
    /*}*/
    /*.kdz-flex-col:nth-child(2) {*/
        /*width: 20%;*/
    /*}*/
    /*.kdz-flex-col:nth-child(3) {*/
        /*width: 60%;*/
    /*}*/
    /*.kdz-flex-col:nth-child(4) {*/
        /*width: 100%;*/
        /*flex-direction: row;*/
        /*display: flex;*/
        /*flex-wrap: wrap;*/
        /*align-items: center;*/
    /*}*/
    /*.filter-main--schlaf {width: 200px;}*/
    /*.filter-main--sitz {width: 198px;}*/
    /*.filter-main--erstzulassung {width: 240px;}*/
    /*.filter-main--neuwagen {*/
        /*width: 120px;*/
    /*}*/
    /*.filter-main--gebrauchtwagen { width: 146px; }*/

    /*.filter-main--schlaf,*/
    /*.filter-main--sitz ,*/
    /*.filter-main--erstzulassung ,*/
    /*.filter-main--neuwagen,*/
    /*.filter-main--gebrauchtwagen { margin-bottom: 8px; }*/
/*}*/

/*@media screen and (max-width: 969px) {*/
    /*.kdz-flex-col:nth-child(1) {*/
        /*width: 100%;*/
    /*}*/
    /*.filter-main--modell {*/
        /*display: flex;*/
        /*flex-wrap: wrap;*/
        /*justify-content: center;*/
    /*}*/
    /*.filter-main--modell > * {*/
        /*margin-bottom: 5px;*/
    /*}*/
    /*.filter-main--modell > *:not(:last-child) {*/
        /*margin-right: 5px;*/
    /*}*/
    /*.kdz-flex-col:nth-child(2) {*/
        /*width: 20%;*/
    /*}*/
    /*.kdz-flex-col:nth-child(3) {*/
        /*width: 80%;*/
    /*}*/
/*}*/

/*@media screen and (max-width: 767px) {*/
    /*.kdz-flex-col:nth-child(2) {*/
        /*width: 100%;*/
    /*}*/
    /*.filter-main--bauart {*/
        /*display: flex;*/
        /*flex-wrap: wrap;*/
        /*justify-content: center;*/
    /*}*/
    /*.filter-main--bauart > * {*/
        /*margin-bottom: 5px;*/
    /*}*/
    /*.filter-main--bauart > *:not(:last-child) {*/
        /*margin-right: 5px;*/
    /*}*/

    /*.kdz-flex-col:nth-child(3) {*/
        /*width: 100%;*/
    /*}*/
    /*.filter-main--laenge {*/
        /*margin-bottom: 40px;*/
    /*}*/
    /*.kdz-flex-col:nth-child(4) {*/
        /*justify-content: center;*/
    /*}*/
/*}*/

