.header-image-carDetail-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  padding-top: 1rem;
}

.header-image-carDetail-wrapper h1 {
  width: auto;
  margin-left: 1rem;
}

.carDetail--images {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  flex-wrap: wrap;
}

.carDetail--images-left {
  width: 100%;
}

.carDetail--images-left .content---image--one img {
  width: 99%;
}

.carDetail--images-thumbs {
  margin-top: 1rem;
}

.carDetail--images-right {
  width: 100%;
}

.carDetail--grundriss {
  background: #f7f9fb;
  padding: 1rem;
}

.carDetail--grundriss a {
  width: 100%;
}

.carDetail--grundriss img {
  width: 100%;
  height: auto;
  display: block;
}

/*.carDetail--360 {*/
/*background: #f7f9fb;*/
/*padding: 0.7rem;*/
/*margin-top: 1rem;*/
/*}*/
/*.carDetail--360 img{*/
/*height: 70px;*/
/*width: 100%;*/
/*}*/
.carDetail--preis {
  background: #f7f9fb;
  padding: 1rem;
  margin-top: 1rem;
  text-align: center;
}

.carDetail--preis h3 {
  font-family: var(--font-bold);
  font-size: 2.3rem;
  /*text-transform: uppercase;*/
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.carDetail--preis p {
  font-family: var(--font-cdmedium);
  font-size: 1.5rem;
  margin-bottom: 0;
  margin-top: 1rem;
  color: var(--color-primary);
  cursor: pointer;

}

.carDetail--preis p:hover {
  color: var(--color-primary-hover);
}

.carDetail--info-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

/*Info block*/
.carDetail--infobox {
  /*background: #f7f9fb;*/
  width: 100%;
  padding: 1rem;

}

.carDetail--infobox h3 {
  font-size: 1.9rem;
  font-family: var(--font-cdmedium);
  font-weight: 300;
  margin-bottom: 1.5rem;
}

.tab-menu {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 0;
  margin-bottom: 1rem;
  padding: 0;
}

.toggle-link {
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  font-family: var(--font-cdmedium);
  font-size: 1.2rem;
  cursor: pointer;
}

.toggle-link.active {
  border-bottom: #f7b800 solid 1px;
}

.toggle.show {
  min-height: 485px;
}

/* Kontakt */
.carDetail--contact {
  width: 100%;
  background: var(--color-primary);
  min-height: 625px;
  padding: 1rem;
}

.carDetail--contact .status-message {
  margin: 0;
}

.carDetail--contact h3 {
  font-size: 1.9rem;
  font-family: var(--font-cdmedium);
  font-weight: 300;
  margin-bottom: 1.5rem;
}

.carDetail--contact p {
  font-family: var(--font-medium);
  font-size: 1.1rem;
}

.carDetail--contact label {
  color: var(--font-color);
}

.carDetail--contact a {
  color: var(--font-color);
}

.full-image img {
  background: #fff;
}

@media screen and (min-width: 48rem) {
  .carDetail--images-left {
    padding: 1rem;
    padding-left: 0;
    width: 65%;
  }

  .carDetail--images-right {
    padding: 1rem;
    padding-right: 0;
    width: 35%;
  }

  /*.carDetail--infobox {*/
  /*width: 64%;*/
  /*}*/
  /*.carDetail--contact {*/
  /*width: 33%;*/
  /*}*/
  .header-image-carDetail-wrapper {
    justify-content: flex-start;
    text-align: left;
    padding-top: 0;
  }
}

/* new page */

#detail {

}

.detail__action-icon {
  width: 100px;
  height: 70px;
  border: 1px solid #707070;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.detail__action-icon > svg {
  width: 80px;
  height: 45px;
}

.detail__action-icon > svg > * {
  fill: #F7B800;
}
#detail-conf-button:hover i,
#detail-info-button:hover i,
.carDetail--360:hover i,
#detail__gallery-action:hover i {
  background: #F7B800;
}
#detail-conf-button:hover .detail__action-icon > svg > *,
#detail-info-button:hover .detail__action-icon > svg > *,
.carDetail--360:hover .detail__action-icon > svg > *,
#detail__gallery-action:hover .detail__action-icon > svg > * {
  fill: #fff;
}

.detail__gallery .carDetail--images-left.gallery {
  display: none;
}

.detail__container {
  justify-content: flex-start;
  display: flex;
  align-items: flex-end;
}

.detail__actions {
  display: flex;
}

.header-image.header-image-carDetail {
  height: 260px;
}

.detail__image.gallery {
  margin-top: -196px;
}

.detail__actions > *:not(:last-child) {
  margin-right: 10px;
}

.detail__hersteller {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #707070;
}

.detail__image.gallery {
  width: calc(40% - 32px * 1 / 2);
  margin-right: 32px;
  height: 355px;
  overflow: hidden;
}

.detail__actions {
  width: calc(60% - 32px * 1 / 2);
  margin-bottom: 40px;
}

.content---image--one {
  height: 100%;
  width: 100%;
  display: inline-block;
}

/* info box */
.detai-info-box__header {
  display: flex;
  margin: 10px 0;
  padding: 10px 0;
}

.detai-info-box__header > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.detai-info-box__header > div {
  width: calc(33.33% - 10px * 2 / 3);
  text-align: center;
}

.detai-info-box__header > div:not(:last-child) {
  margin-right: 10px;
  border-right: 1px solid #c5c5c5;
}

.detai-info-box__header img {
  height: 60px;
  width: auto;
}

.detai-info-box__top {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  color: #fff;
  background: #1D96F2;
  padding: 10px;
  margin: -1px -1px 0 -1px;
  font-family: var(--font-cdmedium);
  font-size: 50px;
}

.detai-info-box {
  border: 1px solid #c5c5c5;
  font-family: var(--font-normal);
  align-self: baseline;
}

#detail th, td {
  border: none;
}

.detai-info-box__label {
  font-family: var(--font-cdmedium);
}

.detai-info-box__header span:not(.detai-info-box__label) {
  font-weight: 600
}

.detai-info-box__body {
  background: #F2F2F2;
}

.detai-info-box__body {
  padding: 10px 20px;
}

.detai-info-box__footer {
  padding: 10px 20px;
}

#detail table {
  margin: 0;
}

.car-box__category {
  margin-left: 10px;
}

.detail__inforow {
  display: flex;
  flex-wrap: wrap;
}

.detai-info-box {
  width: calc(40% - 32px * 1 / 2);
  margin-right: 32px;
}

.detail__grundriss {
  width: calc(60% - 32px * 1 / 2);
}

/* grundrisse */
.grundriss__icon > svg {
  width: 100px;
  height: 70px;
}

.grundriss__icon > svg * {
  fill: #F7B800;
}

.detail__grundrisse {
  width: calc(60% - 32px * 1 / 2);
}

.detail__grundriss.detail__nacht,
.detail__grundriss.detail__tag {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.detail__grundriss.detail__tag > div,
.detail__grundriss.detail__nacht > div {
  height: 290px;
  width: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

/* leistungsslide */
.dleistung__headline {
  text-align: center;
}

.dleistung__headline h2 {
  margin-top: 0;
}

.swiper-button-prev.innenaussen__prev,
.swiper-button-prev.innenaussen__next,
.swiper-button-prev.dleistung__slider--prev,
.swiper-button-next.dleistung__slider--next {
  border: none;
  background: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 40px;
  width: 26px;
}

.swiper-button-next.innenaussen__next,
.swiper-button-next.dleistung__slider--next {
  background-image: url(img/pfeil-rechts-dark.svg);
  right: -60px;
  margin-top: 24px;
}

.swiper-button-prev.innenaussen__prev,
.swiper-button-prev.dleistung__slider--prev {
  background-image: url(img/pfeil-links-dark.svg);
  left: -60px;
  margin-top: 24px;
}

.swiper-container.innenaussen__slider,
.swiper-container.dleistung__slider {
  position: static;
}

.dleistung {
  background: #F2F2F2;
  padding: 3rem 0 5rem 0;
    margin: 2rem 0;
}

.dleistung > .container {
  position: relative;
}

.swiper-pagination.innenaussen__pagination,
.swiper-pagination.dleistung__slider--pagination {
  width: 100%;
  height: 30px;
  /*z-index: 9000;*/
}

.swiper-container-horizontal > .swiper-pagination.innenaussen__pagination.swiper-pagination-bullets,
.swiper-container-horizontal > .swiper-pagination.dleistung__slider--pagination.swiper-pagination-bullets {
  bottom: -38px;
}

.dleistung__headline h2 {
  margin-top: 0;
}

.swiper-container-horizontal > .swiper-pagination.innenaussen__pagination.swiper-pagination-bullets,
.swiper-container-horizontal > .swiper-pagination.dleistung__slider--pagination.swiper-pagination-bullets {
  bottom: -56px;
}

.swiper-pagination.innenaussen__pagination .swiper-pagination-bullet,
.swiper-pagination.dleistung__slider--pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  opacity: 1;
  background: transparent;
  border: 1px solid #F7B800;
}

.swiper-pagination.innenaussen__pagination .swiper-pagination-bullet-active,
.swiper-pagination.dleistung__slider--pagination .swiper-pagination-bullet-active {
  background: #F7B800;
}

.dleistung__slide--inner {
  height: 275px;
  width: 100%;
}

.dleistung__beschreibung {
  display: none;
}

.dleistung__slide {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.dleistung__slide:hover .dleistung__beschreibung {
  position: absolute;
  display: inline-block;
  bottom: 36px;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  z-index: 100;
  color: #fff;
  padding: 10px;
  word-break: break-word;
}

.dleistung__slide:hover .dleistung__slide--inner::before {
  content: '';
  position: absolute;
  display: inline-block;
  background: rgba(0, 0, 0, .4);
  z-index: 50;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.innenaussen__slide {
  height: 840px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.innenaussen {
  padding: 3rem 0 5rem 0;
}

.innenaussen__headline {
  text-align: center;
}

.innenaussen__headline h2 {
  margin-top: 0;
}

.anfrage {
  background: #F2F2F2;
  padding: 3rem 0;
  text-align: center;
}

.anfrage__headline h2 {
  margin-top: 0;
}

.anfrage__headline h2::after {
  content: ' ';
  width: 156px;
  position: absolute;
  background: var(--color-primary);
  bottom: -10px;
  height: 1px;
  left: 50%;
  transform: translate(-50%, 0);
}

.anfrage__button {
  margin-top: 40px;
}

.anfrage__text {
  margin-top: 50px;
}

.carDetail--info-wrapper table tr:nth-child(even) {
  background: #f2f2f2;
}

.techInfo__headline {
  text-align: center;
}

.carDetail--logo-aus {
  width: auto;
  height: 100%;
}

.detail__hersteller {
  height: 70px;
  width: auto;
}

.detai-info-box__price {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F7B800;
    height: 70px;
    font-size: 36px;
    color: #fff;
    margin-right: -1px;
    margin-left: -1px;
}

.detai-info-box {
    overflow: hidden;
    padding-bottom: 30px;
    transition: max-height .4s linear;
    max-height: 560px;
}

.detai-info-box.is--open {
    max-height: 2000px;
}

#detail--more {
    position: absolute;
    bottom: 0;
    left: 0;
    /* transform: translateX(-50%); */
    padding: 10px;
    background: #fff;
    width: 100%;
    text-align: center;

}