.meinungen-wrapper {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: #f2f2f2;
}

.meinungen-wrapper h2 {
  margin-top: 0;
  text-align: center;
}

.meinungen-wrapper h2::after {
  content: "";
  width: 10rem;
  height: 2px;
  display: block;
  position: relative;
  margin: auto;
  margin-top: 1rem;
  background: var(--color-primary);
}

.meinungsbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

.meinungen-img {
  width: 80%;
  padding: 1rem;
  margin-bottom: 2rem;
}

.meinungen-bild {
  width: 100%;
  display: block;
  border: solid 10px white;
  border-radius: 10px;
  box-shadow: 0px 14px 15px 1px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 14px 15px 1px rgba(0, 0, 0, 0.28);
  -webkit-box-shadow: 0px 14px 15px 1px rgba(0, 0, 0, 0.28);
}

.meinungen-text {
  width: 100%;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: auto;
}
.meinungen-name{
  font-family: var(--font-cdbold);
  font-size: 1.3rem;
  text-transform: uppercase;
}
.meinungen-ort{
  font-family: var(--font-medium);
  text-transform: uppercase;
  font-size: 1rem;
  color: var(--color-secondary);
}
.meinungen-bild img {
  width: 100%;
  display: block;
  max-width: 100%;
  height: auto;
}


.left .meinungen-bild {
  transform: rotate(-10deg);
}
.left .meinungen-text {
  text-align: left;
}
.right .meinungen-bild {
  transform: rotate(10deg);
}
.right .meinungen-text {
  text-align: right;
}

@media screen and (min-width: 48rem) {
  .meinungen-img {
    width: 50%;
  }
  .meinungen-text {
    width: 40%;
  }
}