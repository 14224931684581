/* allgemeine Infos und viewport-sx 320px */
.mobile-navigation {
  font-size: var(--font-size);
  overflow-y: scroll;
  overflow-x: scroll;
}
.mobile-navigation ul.main-navigation{
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}
.mobile-navigation a {
  padding: calc(var(--gutter) / 2);
  width: 100%;
  position: relative;
  display: flex;
}

.mobile-navigation .header-logo img {
  margin-right: auto;
  margin-left: auto;
}

.mobile-navigation .first-layer {
  color: var(--color-primary);
}

.mobile-navigation .first-layer:hover {
  color: var(--color-primary);
}

.mobile-navigation .first-layer + ul {
  display: none;
}

.mobile-navigation .first-layer.open + ul {
  display: block;
  box-shadow: none;
  list-style: none;
  position: relative;
  top: 0;
}

.mobile-navigation .first-layer::before {
  border: none;
}

.mobile-navigation .dropdown-list-item {
  border: none;
  padding: 0;
}

.mobile-navigation .dropdown-list-item a {
  margin-bottom: 0;
}

.mobile-navigation .dropdown {
  background-color: transparent;
  position: relative;
  padding: 0;
  padding-left: calc(var(--gutter) * 2);
}

.mobile-navigation .dropdown-link {
  color: white;
}

/*.main-navigation li:hover > .dropdown-link {
  color: var(--color-primary);
}*/

.mobile-navigation li:hover .dropdown-link:hover {
  color: var(--color-grey-dark);
}

.mobile-navigation .dropdown-link-layer-2 {
  color: white;
}

.mobile-navigation li:hover .dropdown-link-layer-2 {
  font-size: var(--font-size);
  color: #ffffff;
}

.mobile-navigation li:hover .dropdown-link-layer-2:hover {
  font-size: var(--font-size);
  color: var(--color-grey-dark);
}

.mobile-navigation .dropdown-list-item ul {
  list-style-type: none;
  margin-bottom: 0;
}

.mobile-navigation .dropdown-list-item li {
  padding: 0;
}
.mobile-navigation .first-layer span {
  padding: 0;
}

.mobile-navigation .first-layer {
  color: #f7b800;
  padding: 10px;
}

.mobile-navigation .submenu.first-layer.menu-icon[title="Unsere Marken"] ~ .dropdown {
  display: none;
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
  box-shadow: none;
  width: 100%;
  top: 0;
  margin-left: 40px;
  margin-top: 20px;
}
.mobile-navigation .active  .submenu.first-layer.menu-icon[title="Unsere Marken"] ~ .dropdown {
  display: block;
}
.mobile-navigation .menu2-img {
  max-width: 100px;
}