/* Main */
.configuration {

}

.configuration .layout-1 {
  text-align: center;
}

.configuration .layout-1 h2 {
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.configuration .layout-1 h2::after {
  content: ' ';
  width: 156px;
  position: absolute;
  background: var(--color-primary);
  bottom: 0;
  height: 1px;
  left: 50%;
  transform: translate(-50%, 0);
}

.is--back-grey {
  background: #F2F2F2;
}

/* Legend */
.configuration__legend {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.configuration__legend__item {
  width: calc(25% - 3px);
  margin-right: 3px;
  background: white;
  padding: 15px 20px;
  text-align: center;
  font-family: var(--font-cdmedium);
  font-size: 26px;
}

.configuration__legend__item.is--active {
  background: var(--color-primary);
  color: white;
}

.configuration__legend__item.is--next-button {
  width: calc(25% - 10px);
  margin-right: 0;
  margin-left: 10px;
  background: var(--color-blue);
  color: white;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.configuration__legend__item.is--next-button:hover {
  background: color(var(--color-blue) shade(20%))
}

.configuration__legend__item.is--next-button::after {
  content: ' ';
  width: 50px;
  height: 100%;
  background: rgba(77, 77, 77, 0.26);
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  background-image: url(img/pfeil-rechts.svg);
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
}

/* Company */

.step-1 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.company-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.company-wrapper__box {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  width: 25%;
  padding: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in;
  text-align: center;
}

.company-wrapper__box:hover {
  background: var(--color-primary);
  color: white;
}

.company-wrapper__box img {
  width: auto;
  max-width: 100%;
  height: 100px;
  margin: auto;
  display: block;
}

.company-wrapper__box h3 {
  margin-top: 20px;
  font-family: var(--font-cdmedium);
  font-size: 26px;
  font-weight: normal;
}

.company-wrapper__box p {
  font-family: var(--font-cdmedium);
  font-size: 18px;
  font-weight: normal;
}

/* Bauformen */
.step-2 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.bauformen-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.bauformen-wrapper__box {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  width: 25%;
  padding: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in;
  text-align: center;
}

.bauformen-wrapper__box:hover {
  background: var(--color-primary);
  color: white;
}

.bauformen-wrapper__box img {
  width: auto;
  height: 100px;
  margin: auto;
  display: block;
  max-width: 100%;
}

.bauformen-wrapper__box h3 {
  margin-top: 20px;
  font-family: var(--font-cdmedium);
  font-size: 26px;
  font-weight: normal;
}

.bauformen-wrapper__box p {
  font-family: var(--font-cdmedium);
  font-size: 18px;
  font-weight: normal;
}

/* Modell Wahl */

.company-titel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.company-titel h3 {
  font-weight: normal;
  font-size: 32px;
  font-family: var(--font-cdbold);
  margin-right: 20px;
  line-height: 40px;
  margin-bottom: 0;
}

.company-titel__logo img {
  max-height: 40px;
}

.modell-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 40px;
}

.modell-wrapper__box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  border-bottom: 1px solid rgba(112, 112, 112, 0.40);
  padding-bottom: 10px;
  padding-top: 10px;
  cursor: pointer;
}

.modell-wrapper__radio {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modell-wrapper__radio--inner {
  height: 26px;
  width: 26px;
  background: white;
  border: 1px solid #707070;
  border-radius: 100px;
  /* padding: 8px; */
  box-shadow: inset 0 0 0 4px #fff;
}

.modell-wrapper__box.is--selected .modell-wrapper__radio--inner {
  background: #1D96F2;
}

.modell-wrapper__car {
  width: 22.5%;
}

.modell-wrapper__car img {
  max-width: 100%
}

.modell-wrapper__schnitt {
  width: 22.5%;
  padding-left: 20px;
}

.modell-wrapper__schnitt img {
  max-width: 100%
}

.modell-wrapper__text {
  width: calc(40% - 50px);
  text-align: left;
  padding-left: 100px;
}

.modell-wrapper__text h4 {
  font-size: 26px;
  font-family: var(--font-cdmedium);
  font-weight: normal;
  margin-bottom: 0px;
  line-height: 38px;
}

.modell-wrapper__text p {
  font-weight: normal;
  font-family: var(--font-cdmedium);
  font-size: 16px;
}

.modell-wrapper__price {
  width: 15%;
  text-align: right;
  font-size: 26px;
  font-family: var(--font-cdmedium);
  padding-right: 20px;
}

/* Zusatzausstattung */
.zusatz-wrapper {
  padding-top: 40px;
  padding-bottom: 40px;
}

.zusatz_category {
  margin-bottom: 100px;
}

.zusatz_category h3 {
  font-family: var(--font-cdbold);
  font-size: 32px;
  font-weight: normal;
  margin-bottom: 40px;
}

.zusatz__list { /* display: flex; */
}

.zusatz__list__item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid rgba(112, 112, 112, 0.4);
  padding-bottom: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}

.zusatz__list__item__check {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.zusatz__list__item__check .check--inner {
  height: 17px;
  width: 17px;
  background: white;
  border: 1px solid #707070;
  /* padding: 8px; */
  box-shadow: inset 0 0 0 2px #fff;
  margin-top: 4px;
}

.zusatz__list__item.is--selected .zusatz__list__item__check .check--inner {
  background: #1D96F2;
}

.zusatz__list__item__text {
  width: calc(80% - 50px);
  display: flex;
}

.zusatz__list__item__text h4 {
  /*font-family: var(--font-medium);*/
  font-weight: normal;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.zusatz__list__item__text p {
  /*font-family: var(--font-medium);*/
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 0;
}

.zusatz__list__item__price {
  text-align: right;
  width: 20%;
  padding-right: 20px;
}

/* Side Fixed Preise */
.price-side {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: fixed;
  right: 0;
  bottom: 20px;
  background: #f7b800;
  color: white;
  font-family: 'Futura Plus W08 Medium';
  font-size: 16px;
  z-index: 3;
  text-align: right;
  padding: 10px;
}

span.price-side__label {
  width: 100%;
  display: block;
  text-align: right;
  font-size: 14px;
  line-height: 16px;
}

.price-side__list {
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px white;
  margin-bottom: 2px;
}

.price-side__grund {
  border-bottom: 1px solid white;
  margin-bottom: 4px;
}

.price-side__end {
  padding-top: 4px;
  border-top: solid 1px white;
}

.price-side__end span.is--bold {
  font-family: 'Futura Plus W08 Bold';
}

.price-side__list span {
  padding-left: 10px;
}

.price-side__list span:not(.price-side__label) {
  margin-bottom: 4px;
  line-height: 16px;
}
.is--disabled .zusatz__list__item__check .check--inner {
  background: #f2f2f2;
}
/*.price-side__list span:not(.price-side__label)::before {*/
/*content: '+';*/
/*position: absolute;*/
/*left: 0;*/
/*}*/
/* Note */

.configuration__note {
  font-family: var(--font-light);
  font-size: 16px;
  margin-top: 40px;
}

.configuration__note ul {
  display: flex;
  justify-content: left;
  list-style: none;
  margin-left: 0;
}

.configuration__note ul li {
  margin-left: 0;
  padding-left: 0;
  padding-right: 8px;
  color: #4d4d4d;
}

.configuration__note ul li::after {
  content: '|';
  position: absolute;
  right: 0;
}

.configuration__note ul li:last-of-type::after {
  content: none;
}

.configuration__note ul li a {
  color: #4d4d4d;
}

.configuration__note ul li::before {
  content: none;
}

/* Result */

#result-step .configuration__legend__item {
  width: calc(33.333333333333333% - 3px);
}

.results__list__item {
  background: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin-bottom: 5px;
  padding: 15px 20px;
  font-family: var(--font-medium);
}

.results__list__item.is--gesamt {
  margin-bottom: 0;
  background: var(--color-blue);
  color: white;
  font-family: var(--font-bold);
}

.results__list__img img {
  height: 18px;
  width: 18px;
}

.results__list__img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.results__list_price {
  text-align: right;
  width: 20%;
}

.results__list_text {
  width: 80%;
}

.configuration__legend__item.is--prev-button {
  text-align: right;
}

.configuration__legend__item.is--start-button {
  text-align: left;
}

.is--black {
  background: #4D4D4D;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.configuration__legend__item.is--prev-button::after {
  content: ' ';
  width: 50px;
  height: 100%;
  background: #373535;;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(img/pfeil-links.svg);
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.configuration__legend__item.is--prev-button:hover,
.configuration__legend__item.is--start-button:hover {
  background: #373535;
}

.configuration__legend__item.is--start-button::after {
  content: ' ';
  width: 50px;
  height: 100%;
  background: #373535;;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  background-image: url(img/pfeil-rechts.svg);
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
}

#conf-form p,
#conf-form h2 {
  text-align: center;
}

/* Helper Klassen */
.is--bold {
  font-family: var(--font-bold);
}

.is--hidden {
  display: none !important;
}

.is--bottom-legend {
  justify-content: flex-end;
}

.is--bottom-legend.two {
  justify-content: space-between;
}

.is--hinweis {
  font-family: var(--font-light);
  font-size: 16px;
}

div#conf-form {
  padding-bottom: 40px;
  padding-top: 40px;
}

.is--conf-send {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.is--conf-send p {
  text-align: left;
  margin-bottom: 0;
  margin-left: 20px;
  color: var(--color-blue);
  font-family: var(--font-bold);
}

.listing--isuseed {
  padding-top: 20px;
  padding-bottom: 20px;
}

.zusatz__list__item.is--sub {
  padding-left: 20px;
}

.zusatz__list__image {
  display: block;
  width: 60px;
  height: 60px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}

@media screen and (max-width: 48rem) {
  .configuration__legend {
    flex-wrap: wrap;
  }

  .configuration__legend__item {
    width: 100%;
    margin-right: 0;
  }

  .configuration__legend__item.is--next-button {
    width: 100%;
    margin-left: 0;
  }

  .bauformen-wrapper,
  .company-wrapper {
    flex-wrap: wrap;
  }

  .bauformen-wrapper__box,
  .company-wrapper__box {
    width: 100%;
  }

  .modell-wrapper__box {
    flex-wrap: wrap;
    padding: 10px;
  }

  .modell-wrapper__car {
    width: 50%;
  }

  .modell-wrapper__radio {
    width: 100%;
  }

  .modell-wrapper__schnitt {
    width: 50%;
  }

  .modell-wrapper__text {
    width: 100%;
    text-align: center;
    padding: 0;
  }

  .modell-wrapper__price {
    width: 100%;
    text-align: center;
    padding: 0;
  }

  .price-side__list {
    display: none;
  }

  .price-side__grund {
    display: none;
  }

  .price-side__end {
    border-top: none;
  }

  #result-step .configuration__legend__item {
    width: 100%;
  }

  .results__list_text {
    width: 60%;
  }

  .results__list_price {
    width: 40%;
  }

  .is--conf-send {
    flex-wrap: wrap;
    justify-content: center;
  }
}