.listing {
    background: white;
}

/* Zusatzausstattung*/
.listing--zusatz {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    background: #f7f8fc;
    padding: 1rem;
    margin-bottom: 1rem;
}

.listing--zusatz-label img {
    max-height: 3rem;
    margin-left: 1rem;
}

.lisiting--zusatz-text {
    font-family: var(--font-cdmedium);
    padding-right: 1rem;
    font-size: 1.5rem;
    text-transform: uppercase;
}

.lisiting--zusatz-text .text-kursiv {
    font-style: italic;
}

.listing--zusatz-label {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}

/* Filter */
.listing--filter {
    width: 100%;
    background: #f7f8fc;
}

.listing--filter-category {
    display: flex;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;
    flex-wrap: wrap;
}

.listing-filter-category-box {
    width: 100%;
    flex-grow: 1;
    font-family: var(--font-cdmedium);
    font-size: 1.4rem;
    padding: 1rem 1rem;
    text-align: center;
    cursor: hand;
    color: var(--font-color);
    transition: background 0.5s ease-in;
}

.listing-filter-category-box:hover {
    background: var(--color-primary);
}

.listing-filter-category-box.aktiv {
    background: var(--color-primary);
    color: #fff;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, .4);
    z-index: 2;
}

.listing--filter-main {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    background: var(--color-primary);
    padding: 1.5rem;
    margin-bottom: 1rem;
    font-family: var(--font-cdmedium);
}

.filter-label {
    font-family: var(--font-cdmedium);
}

.filter-main--laenge {
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-grow: 2;
    width: 100%;
}

.filter-laenge-left,
.filter-laenge-right {
    width: 13%;
}

.filter-laenge-right {
    margin-left: 1.3rem;
}

#filter-preis,
#filter-laenge {
    width: 60%;
}

#filter-preis .noUi-handle,
#filter-laenge .noUi-handle {
    border: solid 1px #eeeeee;
    border-radius: 2px;
    background: white;
    cursor: default;
    box-shadow: 0 3px 6px -3px #BBB;
}

#filter-preis.noUi-horizontal .noUi-handle,
#filter-laenge.noUi-horizontal .noUi-handle {
    width: 15px;
    height: 15px;
    left: -7px;
    top: -4px;
    cursor: pointer;
}

/*.filter-main--sitz,*/
/*.filter-main--schlaf {*/
/*width: 100%;*/
/*padding-top: 2rem;*/
/*flex-grow: 1;*/
/*display: flex;*/
/*justify-content: center;*/
/*align-content: center;*/
/*align-items: center;*/
/*}*/
/*.filter-main--sitz label,*/
/*.filter-main--schlaf label {*/
/*padding-right: 0.8rem;*/
/*display: flex;*/
/*justify-content: center;*/
/*align-items: center;*/
/*align-content: center;*/
/*}*/

/*.filter-main--sitz label input,*/
/*.filter-main--schlaf label input {*/
/*margin-right: 0.2rem;*/
/*font-size: 1rem;*/
/*font-family: var(--font-cdmedium);*/
/*}*/

/* Listing */



.car-box--pic {
    width: 100%;
}

.car-box--action,
.car-box--grundriss {
    width: 100%
}

.car-box--pic img {
    width: 100%;
    height: auto;
    display: block;
}

.car-box--grundriss > img {
    max-width: 100%;
    width: auto;
    display: block;
    height: 200px;
    margin: auto;
}

.car-box--info ul {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
}

.car-box--info ul li {
    width: auto;
    margin: 0.5%;
    background: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 1.2rem;
    padding: .5rem;
}

.car-box--info ul li:before {
    width: 0;
    margin: 0;
    content: '';
}

.car-box--info ul li span {
    padding-left: .5rem;
}

.car-slide--info--text {
    padding-left: .5rem;
}

.car-box--info img {
    height: 1.2rem;
    width: 1.2rem;
}

.car-box--ausstattung img {
    height: 35px;
    width: auto;
    display: block;
    margin: auto;
}

.car-box--action {
    text-align: center;
}

.car-box--action h3 {
    font-family: var(--font-bold);
    font-size: 2.3rem;
    /*text-transform: uppercase;*/
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.car-box--action p {
    font-family: var(--font-cdmedium);
    font-size: 1.5rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
    color: var(--color-primary);
    cursor: pointer;
}

.car-box--action p:hover {
    color: var(--color-primary-hover);
}

.car-box--ausstattung {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

.car-box--ausstattung h3 {
    font-size: 2rem;
    line-height: 2rem;
    margin-left: 1rem;
    padding-bottom: 0.2rem;
}

/* Galary */
.modal-iframe {
    height: 500px;
    width: 100%;
}

.gallery-top {
    height: 400px;
    width: 100%;
}

.gallery-top .swiper-slide {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
}

.gallery-thumbs {
    height: 100px;
    box-sizing: border-box;
    padding: 10px 0;
}

.gal-next,
.gal-prev {
    border: none;
    background: transparent;
    background-repeat: no-repeat;
}

#modal-gallery .modal-header button.close {
    color: white;
    margin-left: auto;
}

/* Ausstatung */
#modal-ausstattung .close {
    margin-left: auto;
}

#modal-ausstattung .modal-body {
    padding: 1rem;
    padding-top: 0;
}

#modal-ausstattung ul {
    padding-left: 40px;
    list-style: none;
}

#modal-ausstattung ul li::before {
    content: "»";
    display: inline-block;
    margin-left: -1.3em;
    width: 1em
}

.legal-hinweis {
    font-size: 13px;
    padding-bottom: 2rem;
}

.kdz-flex-col {
    width: 100%;
}

.kdz-flex-col:nth-child(3) {
    text-align: center;
}

.filter-main--bauart input[type="checkbox"] {
    display: none;
}

.checkbox-icon {
    background: #fff;
    padding: 0.5rem 1rem 1px 16px;
    margin: 5px 0 5px 0;
    border-radius: 4px;
    display: flex;
    width: 150px;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.checkbox-icon.active {
    background: #F7A500;
    color: #fff;
}

.checkbox-icon.active svg * {
    fill: #fff;
}

.checkbox-icon > svg {
    height: 22px;
}

.filter-main--preis {
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-grow: 2;
    width: 100%;
    margin-bottom: 3rem;
}

.filter-main--preis .filter-label,
.filter-main--laenge .filter-label {
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translate(-50%);
}

.filter-main--erstzulassung {
    margin-bottom: 8px;
}

select#erstzulassung-bis,
select#erstzulassung-von {
    padding: 4px;
}

.filter-main--erstzulassung .filter-label {
    margin-bottom: 4px;
}

.kdz-flex-col:nth-child(3) .filter-label {
    font-size: 1.4rem;
}

.filter-main--schlaf .filter-label,
.filter-main--sitz .filter-label {
    width: 137px;
    display: flex;
    justify-content: space-between;
}

.filter-main--schlaf {
    margin-bottom: 8px;
}

.filter-main--sitz {
    margin-bottom: 8px;
}

.filter-main--neuwagen {
    /*margin-bottom: 8px;*/
}

.kdz-flex-col:nth-child(4) {
    font-size: 1.4rem;
}
#filter-sofort[type="checkbox"],
#filter-gebrauchtwagen[type="checkbox"],
#filter-neuwagen[type="checkbox"] {
    width: 20px;
    height: 20px;
    background: #fff;
    border: none;
    box-shadow: none;
    color: #fff;
    outline: none;
    border-radius: 4px;
    -webkit-appearance: none;
    margin-right: 6px;
}
#filter-sofort[type="checkbox"]:checked::after,
#filter-gebrauchtwagen[type="checkbox"]:checked::after,
#filter-neuwagen[type="checkbox"]:checked::after {
    content: '\2713';
    font-size: 14px;
    position: absolute;
    bottom: -2px;
    left: 4px;
    color: #000;
    font-weight: 900;
}
label[for="filter-sofort"],
label[for="filter-gebrauchtwagen"],
label[for="filter-neuwagen"] {
    display: flex;
    align-items: center;
}

.filter-main--schlaf .filter-label,
.filter-main--sitz .filter-label {
    width: 180px;
    align-items: center;
}

select#sitz,
select#schlaf {
    width: 40px;
}

.filter-main--preis {
    position: relative;
}

#min-laenge-value,
#max-laenge-value,
#min-preis-value,
#max-preis-value {
    position: absolute;
    top: 55px;
}

#min-laenge-value,
#min-preis-value {
    left: 133px;
}

#max-laenge-value,
#max-preis-value {
    right: 153px;
}

.kdz-flex-col {
    color: #000;
}

.checkbox-img {
    background: #fff;
    padding: 0.5rem 1rem 1px 16px;
    margin: 5px 0 5px 0;
    border-radius: 4px;
    display: flex;
    width: 150px;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
}

.checkbox-img img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
}

.filter-main--modell > label {
    margin: 5px 0;
}

.filter-main--modell input[type="checkbox"] {
    display: none;
}

span.checkbox-img.active {
    background: #F7A500;
}

.checkbox-img.active img {
    filter: contrast(0) brightness(10);
}

.filter-category__icon {
    display: inline-block;
    width: 22px;
    height: 22px;
    margin-right: 10px;
}



.listing-filter-category-box.aktiv .filter-category__icon svg * {
    fill: #fff;
}

.listing-filter-category-box:hover {
    color: #fff;
    transition: color .5s ease-out;

}

.listing-filter-category-box:hover .filter-category__icon svg * {
    fill: #fff;
    transition: fill .5s ease-out;
}

.listing-filter-category-box.aktiv:hover {
    color: var(--font-color);
}

.listing-filter-category-box.aktiv:hover .filter-category__icon svg * {
    fill: var(--font-color);
}

.listing-filter-category-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.filter-main--erstzulassung {
    display: flex;
    align-items: center;
}

.filter-main--erstzulassung > *:not(:last-child) {
    margin-right: 6px;
}

.listing--filter-category > .listing-filter-category-box:not(.aktiv):not(:last-child) {
    border-right: 1px solid #e6e6e6;
}

.start-search-btn {
    display: flex;
    align-items: center;
    border: 2px solid #fff;
    color: #fff;
    padding: 10px;
    background: #1D96F2;
    cursor: pointer;
}

.start-search-btn__label {
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.start-search-btn__icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.start-search-btn__icon svg > * {
    fill: #fff;
}

#numberofresults {
    margin-right: 8px;
}

.start-search-btn__wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.car-box__main {
    width: 100%;
    padding: 32px;
}
.topviewimage {
    width: 100%;
    height: 300px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}

@media screen and (min-width: 48rem) {
    .listing-filter-category-box {
        width: auto;
    }

    .filter-main--laenge {
        width: auto;
    }

    .filter-main--sitz,
    .filter-main--schlaf {
        width: auto;
    }

    .car-box--pic {
        width: 40%;
    }

    .car-box--action,
    .car-box--grundriss {
        width: 30%
    }

    .car-box--grundriss > img {
        margin: 0;
    }

    .car-box--info ul {
        max-width: 250px;
    }

    .car-box--action {
        text-align: left;
    }

    .car-box--ausstattung img {
        margin: 0;
    }
}

@media screen and (min-width: 64rem) {
    .kdz-flex-col:nth-child(1) {
        width: 15%;
    }
    .kdz-flex-col:nth-child(2) {
        width: 15%;
    }

    .kdz-flex-col:nth-child(3) {
        width: 50%;
    }

    .kdz-flex-col:nth-child(4) {
        width: 20%;
    }
}