.slider-twig {
    max-height: 260px;
    overflow: hidden;
}
.slider-twig .slider .swiper-container {
    width: 100%;
}
.slider-twig .slider .swiper-slide {
    height: 100%;
}
.slider-twig .slider .container-slider {
    width: 100%;
}
.slider-twig .content-slider-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
}
.slider-twig .content-slider-wrapper > .container {
    height: 100%;
}
.slider-twig .slider .slider-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #fff;
}
.slider-twig .slider .slider-content h2,
.slider-twig .slider .slider-content p {
    color: #fff;
}
.slider-twig .slider .slide--button-container {
    text-transform: none;
}
.slider-twig .slider .slider-img {
    width: 100%;
    display: block;
    height: 260px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 50%;
    background-position-x: 50%;
}
.slider-twig .slider .slider-page .swiper-pagination-bullet {
    background: white;
    opacity: 1;
}
.slider-twig .slider .slider-page .swiper-pagination-bullet-active {
    opacity: 1;
    background: var(--color-primary);
}
.slider-twig .image-slider {
    width: 60%;
    margin: auto;
}
.slider-twig .image-slider .image-slider-container,
.slider-twig .image-slider .image-slider-container .swiper-wrapper {
    width: 60%;
}
.swiper-button-next.swiper-button-next--gray {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23AAAAAA'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.swiper-button-prev--gray {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23AAAAAA'%2F%3E%3C%2Fsvg%3E");
}
/* 568 px */
/* 768 px - Tablet */
/* 1024 px - Laptop */
/* 1280 px*/
@media screen and (min-width: 80rem) {
    .slider-twig .slider .slider-img {
        background-position-x: 0;
    }
}



/*.swiper-slide {*/
    /*height: 260px;*/
/*}*/

/*.slider-img {*/
    /*width: 100%;*/
    /*height: 100%;*/
    /*background-size: cover;*/
    /*background-position: center center;*/
/*}*/

/*.container-slider {*/
    /*height: 100%;*/
/*}*/