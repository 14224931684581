.cta {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.cta h2::after {
  content: "";
  width: 160px;
  height: 2px;
  display: block;
  position: relative;
  margin: auto;
  margin-top: 16px;
  background: #f7b800;
}
.cta > .container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
}

.cta-content {
  width: 80%;
  margin: auto;
}
