.content-full {
  margin: 0;
  background: #f2f2f2;
}

.content-full .layout-2 .content-right {
  padding: 2rem 1rem;
}

.content-full .layout-3 .content-left {
  padding: 2rem 1rem;
}
.content-full  .layout-3 .content-right {
  border: none;
}

.full-sub-titel {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--color-primary);
  margin: 0;
  padding: 1rem;
  text-align: center;
  font-family: var(--font-cdbold);
  color: white;
  font-size: 1.4rem;
}
.content-full h2 {
  margin-top: 0;
  margin-bottom: 1rem;
}
.content-full ul{
  margin-left: 0;
}
.content-full ul li {
  font-family: var(--font-cdbold);
  font-size: 1.3rem;
}
.content-full  li:before {
  content: "»";
  display: inline-block;
  margin-left: -1rem;
  width: 1rem;
  color: var(--color-primary);
  font-size: 1.4rem;
}