#listing-cars {
    display: flex;
    flex-wrap: wrap;
}

.listing--car-box {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    /*width: 100%;*/
    /*border-bottom: solid 2px #f7f8fc;*/
    color: var(--font-color);
    font-family: var(--font-cdmedium);
    width: calc(50% - 16px * 1 / 2);
    border: 1px solid #707070;
    margin-bottom: 16px;
}

.listing--car-box:nth-child(odd) {
    margin-right: 16px;

}

.car-box__header {
    display: flex;
    width: 100%;
    margin-top: -1px;
    margin-left: -1px;
}

.car-box__info {
    color: #fff;
    background: #1D96F2;
    width: 130px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.car-box__modellnr {
    font-size: 24px;
    line-height: 40px;
}

.car-box__price {
    color: #fff;
    background: #F7B800;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 130px;
}

.car-box__price-amount {
    font-size: 28px;
}

.car-box__header {
    max-height: 100px;
}

#listing-cars .car-box__image img {
    height: 100%;
    width: auto;
    display: inline-block;
}

.car-box__sleep img,
.car-box__seats2 img,
.car-box__seats1 img {
    height: 32px;
    width: auto;
}

.car-box__seats1,
.car-box__seats2,
.car-box__sleep {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 66px;
    margin: 15px 0px;
}

.car-box__main img {
    width: 100%;
    height: auto;
}

.car-box__sleep {
    border-right: 1px solid #707070;
    border-left: 1px solid #707070;
}

.car-box__image {
    flex-grow: 2;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.car-box__main {
    position: relative;
}

.listing--car-box:hover .car-box__main::before {
    content: '';
    background: rgba(255,255,255,.6);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}
.listing--car-box:hover .car-box__main::after {
    content: '+';
    font-size: 90px;
    color: #fff;

    background: #F7B800;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
}