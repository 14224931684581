.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-slider {
  width: 100%;
}

div#slideback {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  align-content: center;
  height: 100%;
}

.content-slider-wrapper {
  display: block;
  text-align: center;
  align-items: center;
  position: relative;
}

.slider-content-inner {
  margin-left: auto;
  margin-right: auto;
  width: auto;
  display: inline-block;
}

.slider-background-text {
  padding-top: 1rem;
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 1rem;
  width: 100%;
}

.slide--button-container {
  width: 100%;
  word-wrap: normal;
  white-space: normal;

}

.slide--button-container .btn {
  width: auto;
  display: inline-block;
  margin: auto;
  color: white;
  font-size: 16px;
  font-weight: 600;

}

.slider-content {
  font-family: var(--font-bold);
  color: var(--font-color);
  margin-bottom: 0;
  font-size: 20px;
  letter-spacing: 1px;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  position: relative;
  display: block;
}

.slider-content h1,
.slider-content h2 {
  font-family: var(--font-bold);
  text-transform: uppercase;
}

.slider-content h1 {
  font-size: 2.8rem;
  margin-bottom: 0.5rem;
  line-height: 2.8rem;
}

.slider-content h2 {
  margin-top: 0;
}

.slider-icon,
.slider-icon img {
  width: 100%;
}

#slider-button {
  color: var(--font-color);
  text-transform: uppercase;
}
.swiper-button-prev {
  height: 6rem;
  border: solid 1px white;
  display: block;
  width: 3rem;
  content: '';
  border-left: none;
  left: 0;
  background-color: rgba(255,255,255,0.2);
  background-image: url('img/pfeil-links.svg')
}
.swiper-button-next {
  height: 6rem;
  border: solid 1px white;
  display: block;
  width: 3rem;
  content: '';
  border-right: none;
  right: 0;
  background-color: rgba(255, 255, 255, 0.2);
  background-image: url('img/pfeil-rechts.svg')
}
@media screen and (min-width: 35.5rem) {

  .slider-icon,
  .slider-icon img {
    width: 100%;
    position: relative;
    height: auto;
    min-height: inherit;
    left: inherit;
  }

  .slider-icon img {
    max-width: 100%;
    max-height: none;
    height: auto;
  }

  .slider-icon,
  .slider-icon img {
    /*width: 100%;*/
    position: relative;
    height: auto;
    min-height: inherit;
    left: inherit;
  }

  .slider-icon {
    /* height: 900px;*/
    width: 100%;
  }

  .slider-icon img {
    max-width: none;
    /* max-height: 900px;*/
    height: auto;
    width: 100%;
  }

}

@media screen and (min-width: 64rem) {

  .slider-content {
    z-index: 5;
    margin-right: auto;
    margin-left: auto;
    text-align: left;
    display: block;
    height: 100%;
    width: 100%;
    bottom: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background: none;
    color: white;
  }

  .slider-background-text {
    width: 100%;
  }

  .slide--button-container {
    width: 70%;
  }

}

@media screen and (min-width: 80rem) {
  .slider-icon img {
    max-height: none;
  }

  .slider-background-text {
    width: 100%;
  }

  .slide--button-container {
    width: 100%;
  }

}
