.car-slider h2 {
  text-align: center;
}
.car-slider h2::after {
  content: "";
  width: 160px;
  height: 2px;
  display: block;
  position: relative;
  margin: auto;
  margin-top: 16px;
  background: #f7b800;
}

.car-slider {
  text-align: center;
}

.car-slide {
  height: 400px;
  text-align: center;
  font-size: 18px;
  background: #f2f2f2;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /*width: 21% !important;*/
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.15);
}

.car-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-right: 0.5rem;
  padding-left: .5rem;
}

.swiper-container-cars {
  width: 100%;
  height: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.car-slide--image {
  height: 250px;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.car-slide--titel {
  font-family: var(--font-cdbold);
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 0.5rem;
  text-align: left;
  border-left: solid 3px var(--color-primary);
  width: 100%;
}

.car-slide--price {
  font-family: var(--font-cdbold);
  border-right: solid 3px var(--color-primary);
  width: 30%;
  text-align: right;
  padding-right: 0.5rem;
}
.car-slide--titel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.car-slide--price {

}
.car-slide--info {
  display: block;
  width: 100%;
}

.car-slide--info ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}

.car-slide--info ul li {
  width: 100%;
  margin: 0.5%;
  background: white;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  font-size: 0.7rem;
  padding: .5rem;
}

.car-slide--info ul li:before {
  width: 0;
  margin: 0;
  content: '';
}

.car-slide--info ul li span {
  padding-left: .5rem;
}

.car-slide--info img {
  height: 1rem;
  width: 1rem;
}

.car-slide--link {
  padding: 1rem 1rem 1rem 0.5rem;
  text-align: left;
  width: 100%;
}

.car-slide--link a {
  color: var(--font-color);
  font-family: var(--font-normal);
  text-transform: uppercase;
  font-size: 1rem;
  transition: color 0.5s ease-in;
}

.car-slide--link a:hover {
  color: var(--color-primary);
}


.car-slide--next {
  right: 10%;
  background-color: #4d4d4d;
  background-size: 27px 27px;
  border: none;
  height: 3rem;
  width: 3rem;
  color: white;
}
.car-slide--prev{
  left: 10%;
  background-color: #4d4d4d;
  background-size: 27px 27px;
  border: none;
  height: 3rem;
  width: 3rem;
  color: white;
}

@media screen and (min-width: 768px) {
  .car-slide--info ul li {
    width: calc(97% / 3);
  }
  .car-slide--schatten-left {
    content: ' ';

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+53,ffffff+100&1+24,1+24,0.5+87,0+100 */
    background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 24%, rgba(255,255,255,0.77) 53%, rgba(255,255,255,0.4) 87%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 24%,rgba(255,255,255,0.77) 53%,rgba(255,255,255,0.4) 87%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 24%,rgba(255,255,255,0.77) 53%,rgba(255,255,255,0.4) 87%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 80%;
    z-index: 9;
  }

  .car-slide--schatten-right {
    content: ' ';
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+47,ffffff+100&0+0,0.5+13,1+76,1+76 */
    background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.4) 13%, rgba(255,255,255,0.77) 47%, rgba(255,255,255,1) 76%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.4) 13%,rgba(255,255,255,0.77) 47%,rgba(255,255,255,1) 76%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.4) 13%,rgba(255,255,255,0.77) 47%,rgba(255,255,255,1) 76%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 80%;
    z-index: 9;

  }
  .car-wrapper {
    padding-right: 0;
    padding-left: 0;
  }
}