.referenzen {
  background: #393939;
  padding: 2rem 0;
  color: white;
}

.referenzen--boxen {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-top: 1rem;
  flex-wrap: wrap;
}

.referenz--box {
  width: 100%;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.referenz--box::after {
  content: ' ';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgb(0, 0, 0);
  opacity: .6;
  transition: opacity .40s ease-out;
}

.referenz--box:hover::after {
  opacity: 0;
}

.referenz--box--content {
  display: flex;
  position: absolute;
  bottom: -5rem;
  left: 0;
  background: white;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  transition: bottom .40s ease-in;
  z-index: 20;
}

.referenz--box:hover .referenz--box--content {
  bottom: 0;
}

.referenz--box--content h3 {
  color: var(--font-color);
  font-family: var(--font-cdlight);
  font-size: 1.1rem;
  margin: 0;
  padding: .7rem 0.5rem;

}

.referenz-button {
  width: 3rem;
  height: 3rem;
  padding: 0;
  display: block;
  margin-left: auto;
}

.toggle-cross-top {
  transform: rotate(90deg);
}

.toggle-cross-line {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  display: block;
  left: 50%;
  top: 35%;
  border-left: 2px solid white;
  transition: .55s;
  height: 14px;
}

.referenzen--link {
  padding: 2rem 0;
  text-align: center;
  width: 100%;
}

.referenzen--link a {
  font-family: var(--font-normal);
  font-size: 1.2rem;
}

@media screen and (min-width: 35.5rem) {
  .referenzen--boxen {
    justify-content: flex-start;
  }

  .referenz--box {
    width: calc(97% / 2);
    overflow: hidden;
    margin-left: 0.5%;
    margin-right: 0.5%;
  }
}

@media screen and (min-width: 48rem) {
  .referenz--box {
    width: calc(96% / 3);
    overflow: hidden;
    margin-left: 0.5%;
    margin-right: 0.5%;
  }
}
