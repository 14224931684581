.finanz {
  padding-top: 40px;
  padding-bottom: 40px;
}

h2.finanz__titel {
  text-align: center;
}

h2.finanz__titel::after {
  content: ' ';
  width: 156px;
  position: absolute;
  background: #f7b800;
  height: 1px;
  left: 50%;
  bottom: -10px;
  transform: translate(-50%, 0);
}

.finanz__image {
  width: calc(60% - 16px);
}

.finanz__hersteller {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 70px;
  background: white;
  z-index: 2;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.finanz__img-wrapper {
  width: 100%;
  height: 560px;
  background: #f2f2f2;
}

.finanz__action {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}

a.action__button {
  padding: 10px;
  background: #f7b800;
  width: 50px;
  height: 50px;
  transition: all 0.3ms ease;

}

a.action__button:hover {
  background: color(#f7b800 shade(20%));
}

a.action__button.is--print {
  background: #707070;
}

a.action__button.is--print:hover {
  background: color(#707070 shade(20%));
}

.action__button svg {
  height: 30px;
  width: 30px;
  fill: #fff;
}


.finanzierung__form__item {
  background: white;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
  padding: 10px;
  height: 50px;
  align-items: center;
  align-content: center;
  font-family: var(--font-normal);
  font-weight: normal;
  border-bottom: 1px solid rgba(112, 112, 112, 0.40);
}

.finanzierung__form__label {
  width: 50%;
}

.finanzierung__form__value {
  width: 50%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}

.text-after {
  margin-left: 10px;
}


.finanzierung__form__value input {
  width: 100px;
  text-align: right;
}

.finanzierung__form__value select {
  background-color: white;
  border-radius: 0 !important;
  width: 60px;
  height: 30px;
  padding-left: 5px;
  border-top-left-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
}

.finanzierung__hinweis {
  font-family: var(--font-light);
  text-align: center;
  padding: 30px;
}

.finanzierung__action {
  text-align: center;
  padding-bottom: 2rem;
}
.finanzierung__titel {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;

}
.finanzierung__titel h2 {
  margin-bottom: 0;
}
.fin-contact__titel {
  text-align: center;
}
div#finanzierung-con {
  padding-top: 40px;
  padding-bottom: 20px;
}