.news {
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 2rem;
  padding-bottom: 0;
  background: var(--color-background-dark);
}

.news .container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 1rem;
}

.news-wrapper {
  width: 100%;
  padding-bottom: 1rem;
}

.news--boxen {
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 1rem;
}

section.news.news--content .news--boxen {
  justify-content: flex-start;
}

.news--box {
  width: 100%;
  margin-bottom: 1rem;

  text-align: left;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
}

.news--box--img-container {
  overflow: hidden;
}

.news-date {
  border-bottom: solid 1px #ddddde;
  padding-bottom: 0.5rem;
}

.news--box--content {
  margin-bottom: 1rem;
  background: white;
  padding: 1rem;

}

.news--box--content h3 {
  font-size: 1.2rem;
  font-family: var(--font-normal);
  padding-bottom: 1rem;
  padding-top:0;
  margin: 0;
  text-transform: uppercase;
  color: var(--font-color);
}
.news--box--content > a > h3 {
  color: var(--font-color);
}
.news--box--content > a:hover > h3 {
  color: var(--color-primary);
}

.news--box--link-container {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding-top: 1rem;
}

@media screen and (min-width: 35.5rem) {

}

@media screen and (min-width: 48rem) {
  .news {
    padding-bottom: 2rem;
  }
  .news--box {
    height: 16rem;
    margin-bottom: 2rem;
  }
  section.news.news--content .news--box {
    width: calc(96% / 2);
    margin-right: 2%;
  }

  .news--box--img-container {
    height: 14rem;
  }
  .news--box--img-container {
    width: 90%;
  }

  .news--box--content {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0;
    width: 65%;
    min-height: 9rem;
    box-shadow:1px 5px 15px 2px rgba(0,0,0, 0.45);
    -moz-box-shadow:1px 5px 15px 2px rgba(0,0,0, 0.45);
    -webkit-box-shadow:1px 5px 15px 2px rgba(0,0,0, 0.45);
  }
}

@media screen and (min-width: 64rem) {
}