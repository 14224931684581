@media screen and (max-width: 1024px) {
    .swiper-button-next.innenaussen__next, .swiper-button-next.dleistung__slider--next {
        right: 0
    }
    .swiper-button-prev.innenaussen__prev, .swiper-button-prev.dleistung__slider--prev {
        left: 0;
    }
}

@media screen and (max-width: 947px) {
    .main-navigation li > a {
        padding: 5px 0
    }
    .main-navigation li > a > span {
        padding: 11px 6px;
    }
    .detail__container {
        flex-wrap: wrap;
    }
    .detail__grundrisse,
    .detai-info-box,
    .detail__actions,
    .detail__image.gallery {
        width: 100%;
        margin: 0;
        margin-bottom: 16px;
    }
    .detail__actions {
        justify-content: center;
    }
}

@media screen and (max-width: 843px) and (min-width: 768px) {
    .main-navigation li > a {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 767px) {
    .innenaussen__slide {
        height: 500px;
    }
}

@media screen and (max-width: 653px) {
    .footer__hersteller > .container {
        flex-wrap:wrap;
    }
    .footer__hersteller-image {
        margin-bottom: 16px;
    }
}

@media screen and (max-width: 478px) {
    .detail__actions {
        flex-wrap: wrap;
    }
    .detail__actions > div {
        width: calc(50% - 16px * 1 / 2);
        margin: 0;
    }

    .detail__actions > div:nth-child(n) {
        margin: 0;
        margin-bottom: 16px;
    }
    .detail__actions > div:nth-child(odd) {
        margin-right: 16px;
    }
    .detail__action-icon {
        width: 100%;
    }

    .detail__grundriss.detail__nacht,
    .detail__grundriss.detail__tag {
        flex-wrap: wrap;
        justify-content: center;
    }
    i.grundriss__icon {
        order: 1;
    }
    .detail__grundriss.detail__tag > div, .detail__grundriss.detail__nacht > div {
        order: 2;
        height: 200px;
    }
}

@media screen and (max-width: 399px) {
    .detail__grundriss.detail__tag > div, .detail__grundriss.detail__nacht > div {

        height: 170px;
    }
    .innenaussen__slide {
        height: 400px;
    }
    .detail__image.gallery {
        height: 160px;
    }
}


