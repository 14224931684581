.pure-g {
  display: flex;
  flex-wrap: wrap;
}

.container {
  margin: 0 0.5rem;
}
.layout-1 h2 {
  text-align: center;
}
@media screen and (--viewport-lg) {
  .container {
    width: 60rem;
    margin: 0 auto;
  }
}

@media screen and (--viewport-xl) {
  .container {
    width: 75rem;
  }
}
