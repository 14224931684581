.layout-6--content {
  text-align: center;
}

.layout-6--content h2 {
  text-align: center;
}

.layout-6--content h3 {
  text-align: center;
}

.layout-6--image {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: block;
}

.layout-6--image .content-mutiple-images {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.layout-6.content .gallery a {
  width: 100%;
  margin: 0;

}

.layout-6 .header-trenner {
  width: 40%;
}

@media screen and (min-width: 48rem) {
  .layout-6.content .gallery a {
    width: 25%;
  }
  .layout-6.content .gallery a.content---image--one {
    width: 100%;
  }
}